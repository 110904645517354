import { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import Form from "../../utils/Form";
import api from "../../../service/api";
import Loading from "../../utils/Loading";
import Notification from "../../utils/Notification";

import styles from "./styles.module.css";

const { container_input, buttons, buttons_actions } = styles;

export default function SolicitarAuxilioModal({
  isModalSolicitarAuxilioOpen,
  closeModalSolicitarAuxilio,
  propostas,
  botao,
}) {
  const [loading, setLoading] = useState(false);
  const [dadosDevedor, setDadosDevedor] = useState({});
  const [observacao, setObservacao] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState("");
  const [alertInfo, setAlertInfo] = useState("");

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { corFundoBotao, corTextoBotao } = botao;

  const handleEnviarEmail = async () => {
    setLoading(true);
    try {
      let email_assessoria01;
      let email_assessoria02;

      if (propostas) {
        email_assessoria01 = propostas[0]?.portalneg_email01;
        email_assessoria02 = propostas[0]?.portalneg_email02;

        const tipoEmail = 2;

        const {
          nome: nomeDevedor,
          email: emailDevedor,
          telefone: telefoneDevedor,
        } = dadosDevedor;

        if (!nomeDevedor || !emailDevedor || !telefoneDevedor) {
          setAlertInfo("Preenchimento de dados obrigatório!");
          setAlertInfoTipo("error");
          setOpenAlert(true);
          setLoading(false);

          return;
        }

        const dadosEmailContraProposta = {
          nomeDevedor,
          emailDevedor,
          telefoneDevedor,
          processo: propostas[0]?.processo,
          cnpj: propostas[0]?.cnpj,
          tipoEmail,
          contraProposta: observacao,
          email_assessoria01,
          email_assessoria02,
          id,
        };

        const bodyDadosDevedor = dadosEmailContraProposta;

        const URL = `/envio_email_portal_neg`;

        const resp = await api.post(URL, { bodyDadosDevedor });
        if (resp.status === 200) {
          setAlertInfo("E-mail enviado com sucesso!");
          setAlertInfoTipo("success");
          setOpenAlert(true);
        }
      }

      setLoading(false);
      closeModalSolicitarAuxilio();
    } catch (error) {
      setAlertInfo("Erro ao enviar o e-mail!");
      setAlertInfoTipo("error");
      setOpenAlert(true);

      setLoading(false);
      closeModalSolicitarAuxilio();
    }
  };

  return (
    <>
      <Dialog
        open={isModalSolicitarAuxilioOpen}
        onClose={closeModalSolicitarAuxilio}
      >
        <DialogTitle>CONTATO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha seus dados abaixo que entraremos em contato
          </DialogContentText>
          <div className={container_input}>
            <Form setDadosDevedor={(e) => setDadosDevedor(e)} />
            <div>
              <TextField
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 30000,
                }}
                margin="dense"
                style={{
                  marginTop: ".9rem",
                }}
                id="obs"
                name="obs"
                label="Observações"
                onChange={(e) => setObservacao(e.target.value)}
                type="text"
                minRows={4}
                maxRows={4}
                multiline
                fullWidth
              />
            </div>
          </div>

          <div className={buttons_actions}>
            <Button
              type="submit"
              variant="contained"
              className={buttons}
              style={{
                width: "140px",
                marginRight: "5px",
                backgroundColor: corFundoBotao,
                color: corTextoBotao,
              }}
              color="primary"
              onClick={handleEnviarEmail}
            >
              Enviar
            </Button>
            <Button
              variant="contained"
              color="info"
              className={buttons}
              style={{
                width: "100px",
                marginLeft: "5px",
              }}
              onClick={closeModalSolicitarAuxilio}
            >
              Voltar
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Notification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />

      <Loading carregar={loading} />
    </>
  );
}
