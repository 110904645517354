import React from "react";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={props.openAlert}
      autoHideDuration={3000}
      onClose={(reason) =>
        reason === "clickaway" ? 1 : props.callbackParent()
      }
    >
      <Alert
        onClose={() => props.callbackParent()}
        severity={props.alertInfoTipo}
      >
        {props.alertInfo}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
