import { FormHelperText, TextField } from "@mui/material";
import PhoneInput from "./PhoneInput";
import { useEffect, useState } from "react";

export default function Form({ setDadosDevedor }) {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [nome, setNome] = useState("");
  const [errorNome, setErrorNome] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [errorTelefone, setErrorTelefone] = useState(false);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);
    setErrorEmail(!isValid);
  };

  const handleNomeChance = (e) => {
    const inputNome = e.target.value;
    setNome(inputNome);

    const isValid = Boolean(inputNome.trim());
    setErrorNome(!isValid);
  };

  const handleTelefoneChance = (e) => {
    const inputTelefone = e.target.value;
    setTelefone(inputTelefone);

    const isValid = Boolean(inputTelefone.trim());
    setErrorTelefone(!isValid);
  };

  useEffect(() => {
    if (email || nome || telefone) {
      const dadosDevedor = {
        nome,
        email,
        telefone,
      };
      setDadosDevedor(dadosDevedor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, nome, telefone]);

  return (
    <div style={{ width: "25rem" }}>
      <div>
        <TextField
          id="nome"
          fullWidth
          type="text"
          label="Nome"
          variant="outlined"
          placeholder="Digite seu nome"
          style={{
            marginTop: "1rem",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={nome}
          onChange={handleNomeChance}
          error={errorNome}
        />
        {errorNome && (
          <FormHelperText error>Campo "Nome" obrigatório</FormHelperText>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <TextField
          id="email"
          fullWidth
          type="text"
          label="E-mail"
          variant="outlined"
          placeholder="Digite seu e-mail"
          style={{
            marginTop: "1rem",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={handleEmailChange}
          error={errorEmail}
        />
        {errorEmail && (
          <FormHelperText error>
            Por favor, insira um endereço de e-mail válido.
          </FormHelperText>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <PhoneInput
          label="Telefone"
          style={{
            marginTop: "1rem",
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Ex. (11) 9 9999 - 9999"
          fullWidth
          id="telefone"
          type="tel"
          value={telefone}
          onChange={handleTelefoneChance}
          error={errorTelefone}
        />
        {errorTelefone && (
          <FormHelperText error>Campo "Telefone" obrigatório</FormHelperText>
        )}
      </div>
    </div>
  );
}
