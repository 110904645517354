import { useEffect, useState } from "react";
import Routes from "./Routes";
import { useCustomizacaoOptions } from "./context/CustomizacaoOptions";

import styles from "./styles.module.css";

function App() {
  const [urlImagem, setUrlImagem] = useState("");
  const [corDeFundo, setCorDeFundo] = useState("#DEE6EB");
  const { container } = styles;
  const { customizacaoOptions } = useCustomizacaoOptions();

  useEffect(() => {
    if (customizacaoOptions) {
      const url = customizacaoOptions.url_imagem_background;
      const fundo = customizacaoOptions.background_color;
      setUrlImagem(url);
      setCorDeFundo(fundo);
    }
  }, [customizacaoOptions]);

  return (
    <div
      className={container}
      style={{
        backgroundColor: corDeFundo,
        backgroundImage: `url(${urlImagem || ""})`,
        backgroundSize: "cover",
      }}
    >
      <Routes />
    </div>
  );
}

export default App;
