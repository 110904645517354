import { createContext, useContext, useState } from "react";

const CustomizacaoOptionsContext = createContext();

export const useCustomizacaoOptions = () => {
  return useContext(CustomizacaoOptionsContext);
};

export function CustomizacaoOptionsProvider({ children }) {
  const [customizacaoOptions, setCustomizacaoOptions] = useState({});

  const definirCustomizacao = (options) => {
    if (options) {
      setCustomizacaoOptions(options);
    }
  };

  return (
    <CustomizacaoOptionsContext.Provider
      value={{
        customizacaoOptions,
        definirCustomizacao,
      }}
    >
      {children}
    </CustomizacaoOptionsContext.Provider>
  );
}

export const useCustomizacaoOptionsContext = () =>
  useContext(CustomizacaoOptionsProvider);
