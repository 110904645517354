import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";

//import { useFaturaModal } from "../../../context/FaturasModal";
import { useCustomizacaoOptions } from "../../../context/CustomizacaoOptions";

import styles from "./styles.module.css";

export default function Header() {
  const { header, voltar, title } = styles;

  const [isVoltarOff, setIsVoltarOff] = useState(true);
  const [nDoc, setNDoc] = useState("");
  const [hashEmp, setHashEmp] = useState("");
  const [corFundo, setCorFundo] = useState("#6089e0");
  const [corTexto, setCorTexto] = useState("#fff");
  //const [corFundoBotao, setCorFundoBotao] = useState("#AEB6BF");
  //const [corTextoBotao, setCorTextoBotao] = useState("#fff");

  const [textoHeader, setTextoHeader] = useState("");

  const location = useLocation();
  //const { openModal, isFaturaGerada, isOnlyFaturas } = useFaturaModal();
  const { customizacaoOptions } = useCustomizacaoOptions();

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    const hashEmpresa = location.pathname.split("/")[2];

    const { x } = JSON.parse(localStorage.getItem("user")) || {};

    setHashEmp(hashEmpresa);
    setNDoc(x);

    if (path === "negociacao") {
      setIsVoltarOff(false);
    } else {
      setIsVoltarOff(true);
    }
  }, [location]);

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.header_footer_color;
      const corTexto = customizacaoOptions.header_footer_text_color;
      const texto = customizacaoOptions.header_text;
      const fundoBotao = customizacaoOptions.button_color;
      const corTextoBotao = customizacaoOptions.button_text_color;

      setCorFundo(fundo);
      setCorTexto(corTexto);
      setTextoHeader(texto);
      //setCorFundoBotao(fundoBotao);
      //setCorTextoBotao(corTextoBotao);
    }
  }, [customizacaoOptions]);

  return (
    <header style={{ backgroundColor: corFundo }} className={header}>
      <div style={{ width: "1rem" }}>
        <Link
          hidden={isVoltarOff}
          to={`/principal/${hashEmp}?d=${nDoc}`}
          reloadDocument
        >
          <Tooltip title="Voltar">
            <IconButton style={{ marginLeft: "5px", color: corTexto }}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
      <h1 className={title} style={{ color: corTexto }}>
        {textoHeader}
      </h1>

      <div>
        {/*
        <Button
          variant="contained"
          style={{
            marginRight: "5px",
            backgroundColor: corFundoBotao,
            color: corTextoBotao,
            //display: !isFaturaGerada || isOnlyFaturas ? "none" : "",
            display: !isFaturaGerada ? "none" : "",
          }}
          onClick={() => openModal()}
        >
          <AttachMoneyIcon />
          Faturas
        </Button>
        */}

        <Link to={`/login`} onClick={() => localStorage.clear()} reloadDocument>
          <Tooltip title="Sair">
            <IconButton style={{ marginRight: "5px", color: corTexto }}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
    </header>
  );
}
