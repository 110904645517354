import styles from "./styles.module.css";
import api from "../../../service/api";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCustomizacaoOptions } from "../../../context/CustomizacaoOptions";

const { footer } = styles;

export default function Footer() {
  const [nomeAssessoria, setNomeAssessoria] = useState("");
  const [assessoriaLinha1, setAssessoriaLinha1] = useState("");
  const [assessoriaLinha2, setAssessoriaLinha2] = useState("");
  const [corFundo, setCorFundo] = useState("#6089e0");
  const [corTexto, setCorTexto] = useState("#ffffff");

  const location = useLocation();
  const { customizacaoOptions } = useCustomizacaoOptions();
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    async function handleGetDadosAssessoria() {
      if (id != null) {
        try {
          await api.get(`/dados_assessoria_cod/${id}`).then((result) => {
            if (result.data.rows.length > 0) {
              setNomeAssessoria(result.data.rows[0].emp_nome);
              setAssessoriaLinha1(result.data.rows[0].portalneg_linha1);
              setAssessoriaLinha2(result.data.rows[0].portalneg_linha2);
            }
          });
        } catch (error) {}
      }
    }

    handleGetDadosAssessoria();
  }, [id]);

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.header_footer_color;
      const texto = customizacaoOptions.header_footer_text_color;
      setCorFundo(fundo);
      setCorTexto(texto);
    }
  }, [customizacaoOptions]);

  return (
    <footer className={footer} style={{ backgroundColor: corFundo }}>
      <h5
        style={{ color: corTexto }}
      >{`${nomeAssessoria} / ${assessoriaLinha1} / ${assessoriaLinha2}`}</h5>
      {/* como tava no sistema antigo  */}
      {/* <h5>{nomeAssessoria}</h5>
      <h7>{assessoriaLinha1}</h7>
      <h7>{assessoriaLinha2}</h7> */}
    </footer>
  );
}
