import api from "../api";

const getCustomizacao = async (conta) => {
  try {
    const response = await api.get(`/customizacao_portal/${conta}`);
    return response;
  } catch (error) {
    return {
      status: error.response?.status,
      error: error.response?.data.error,
    };
  }
};

export const customizacaoService = {
  getCustomizacao,
};
