import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Authenticate } from "./HookAuth";

const Auth = ({ autenticado = false, children }) => {
  const [auth, setAuth] = useState(autenticado);

  useEffect(() => {
    const { userIsAuthenticate } = Authenticate;
    const isAuthenticate = userIsAuthenticate();
    setAuth(isAuthenticate);
  }, []);

  if (!auth && window.location.pathname !== "/login") {
    return <Navigate to="/login" />;
  }

  return children;
};

export default Auth;
