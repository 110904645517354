import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import colors from "../../../constant/colors";

const theme = createTheme({
  palette: {
    primary: { main: colors.BUTTON_COLOR },
  },
});

export default function TermosAceito({
  isModalTermosAceitostaOpen,
  closeModalTermosAceito,
  termos = "",
  corFundoBotao = "#000",
  corTextoBotao = "#FFF",
}) {
  return (
    <>
      <Dialog
        open={isModalTermosAceitostaOpen}
        onClose={closeModalTermosAceito}
        maxWidth="md"
      >
        <DialogTitle>Termos de Privacidade e Responsabilidade</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{
                __html: termos.replace(/(<? *script)/gi, "illegalscript"),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              autoFocus
              onClick={closeModalTermosAceito}
              style={{
                marginRight: "5px",
                backgroundColor: corFundoBotao,
                color: corTextoBotao,
              }}
            >
              Fechar
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  );
}
