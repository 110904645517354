import axios from "axios";

const api = axios.create({
  //baseURL: 'http://localhost:3333'
  //baseURL: 'http://192.168.15.128:3333'
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const api_key = "7c358afd-f805-4134-aef1-279da82d28a5";
  config.headers.api_key = `${api_key}`;
  return config;
});

export default api;
