import { TextField } from "@mui/material";
import { PatternFormat } from "react-number-format";

export default function PhoneInput(props) {
  return (
    <PatternFormat
      format={
        (props.value || "").length <= 10
          ? "(##) ####-#####"
          : "(##) # ####-####"
      }
      customInput={TextField}
      {...props}
    />
  );
}
