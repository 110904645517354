import { Dialog, DialogContent } from "@mui/material";
import { useFaturaModal } from "../../../context/FaturasModal";
import FaturaTable from "../../utils/faturaTable";

export default function FaturasModal() {
  const { isOpen, closeModal } = useFaturaModal();

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="md">
      <DialogContent>
        <FaturaTable />
      </DialogContent>
    </Dialog>
  );
}
