import api from "../api";

const cadastrarFatura = async (id, body) => {
  const URL = `/cadastrar_fatura/${id}`;

  try {
    const response = await api.post(`${URL}`, body);

    return response;
  } catch (error) {
    return {
      status: error.response.status,
      error: error.response.data.error,
    };
  }
};

const listarFaturas = async (params) => {
  const { uidDevedor, id } = params;
  const URL = `/listar_faturas/${id}?d=${uidDevedor}`;

  try {
    const response = await api.get(URL);
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      error: error.response.data.error,
    };
  }
};

export const faturaService = {
  cadastrarFatura,
  listarFaturas,
};
