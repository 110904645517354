import api from "../api";

const getPropostas = async (id, proc, forma_pagto) => {
  try {
    const URL = `/listar_negociacao/${id}`;
    const response = await api.get(
      `${URL}?d=${proc}&forma_pagto=${forma_pagto}`
    );
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      error: error.response.data.error,
    };
  }
};

export const propostaService = {
  getPropostas,
};
