import { useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { Barcode, CreditCard } from "@phosphor-icons/react";
import PixIcon from "@mui/icons-material/Pix";

import styles from "./styles.module.css";

const { button_pagamentos } = styles;

export default function FormaPagtoModal({
  isModalFormaPagtoOpen,
  closeModalFormaPagto,
  botao,
  formaPagto,
}) {
  const { corFundoBotao, corTextoBotao } = botao;

  const handleSelecionarFormaPagtoBoleto = () => {
    closeModalFormaPagto("BOLETO BANCÁRIO");
  };
  const handleSelecionarFormaPagtoPix = () => {
    closeModalFormaPagto("PIX");
  };
  const handleSelecionarFormaPagtoCartao = () => {
    closeModalFormaPagto("CARTÃO DE CRÉDITO");
  };

  const styleFormaPagto = {
    borderColor: corFundoBotao,
    color: corFundoBotao,
  };
  const styleFormaPagtoSelecionada = {
    backgroundColor: corFundoBotao,
    color: corTextoBotao,
  };

  return (
    <>
      <Dialog
        open={isModalFormaPagtoOpen}
        onClose={() => closeModalFormaPagto("")}
      >
        <DialogTitle>FORMA DE PAGAMENTO</DialogTitle>
        <DialogContent>
          <div>
            <Button
              fullWidth
              variant="outlined"
              style={
                formaPagto == "BOLETO BANCÁRIO"
                  ? styleFormaPagtoSelecionada
                  : styleFormaPagto
              }
              onClick={handleSelecionarFormaPagtoBoleto}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Barcode style={{ fontSize: "40px" }} />
                </Grid>
                <Grid item>
                  <span>Boleto Bancário</span>
                </Grid>
              </Grid>
            </Button>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              fullWidth
              variant="outlined"
              style={
                formaPagto == "PIX"
                  ? styleFormaPagtoSelecionada
                  : styleFormaPagto
              }
              onClick={handleSelecionarFormaPagtoPix}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <PixIcon style={{ fontSize: "40px" }} />
                </Grid>
                <Grid item>
                  <span>Pix</span>
                </Grid>
              </Grid>
            </Button>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              fullWidth
              variant="outlined"
              style={
                formaPagto == "CARTÃO DE CRÉDITO"
                  ? styleFormaPagtoSelecionada
                  : styleFormaPagto
              }
              onClick={handleSelecionarFormaPagtoCartao}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CreditCard style={{ fontSize: "40px" }} />
                </Grid>
                <Grid item>
                  <span>Cartão de Crédito</span>
                </Grid>
              </Grid>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
