import { createContext, useContext, useState } from "react";

const FaturaModalContext = createContext();

export const useFaturaModal = () => {
  return useContext(FaturaModalContext);
};

export function FaturaModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [faturasGeradas, setFaturasGeradas] = useState([]);
  const [isOnlyFaturas, setIsOnlyFaturas] = useState(false);
  const [isFaturaGerada, setIsFaturaGerada] = useState(false);
  const [processoSelecionado, setProcessoSelecionado] = useState(null);
  const [uidDevedor, setUidDevedor] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setProcessoSelecionado(null);
  };

  const definirFaturas = (faturas) => {
    if (faturas) {
      setFaturasGeradas(faturas);
    }
  };

  return (
    <FaturaModalContext.Provider
      value={{
        isOpen,
        closeModal,
        openModal,
        definirFaturas,
        faturasGeradas,
        isOnlyFaturas,
        setIsOnlyFaturas,
        isFaturaGerada,
        setIsFaturaGerada,
        processoSelecionado,
        setProcessoSelecionado,
        uidDevedor,
        setUidDevedor,
      }}
    >
      {children}
    </FaturaModalContext.Provider>
  );
}

export const useFaturaModalContext = () => useContext(FaturaModalProvider);
