import { Backdrop, CircularProgress } from "@mui/material";

const Loading = (props) => {
  return (
    <Backdrop
      sx={{
        zIndex: 3500,
        color: "#fff",
      }}
      open={props.carregar}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
