import api from "../api";

const getDebitos = async (id, ndoc) => {
  const URL = `/listar_processos/${id}`;

  try {
    const response = await api.get(`${URL}?d=${ndoc}`);
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      error: error.response.data.error,
    };
  }
};

export const debitosService = {
  getDebitos,
};
