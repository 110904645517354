import axios from "axios";
import api from "../api";

const login = async (body) => {
  try {
    const response = await api.post("/login", body);
    return response;
  } catch (error) {
    return {
      status: error.response?.status,
      error: error.response?.data.error,
    };
  }
};

const getMyIp = async () => {
  try {
    const response = await axios.get("https://jsonip.com");
    const ipAddr = response.data.ip;

    return { meuIP: ipAddr };
  } catch (error) {
    return {
      status: error.response?.status,
      error: error.response?.data.error,
    };
  }
};

const getDadosAssessoria = async (conta) => {
  try {
    const response = await api.get(`/dados_assessoria/${conta}`);
    return response;
  } catch (error) {
    return {
      status: error.response?.status,
      error: error.response?.data.error,
    };
  }
};

export const loginService = {
  login,
  getMyIp,
  getDadosAssessoria,
};
