import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Negociacao from "./pages/negociacao";
import Auth from "./auth/Auth";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";

import Login from "./pages/login";
import Login2 from "./pages/login2";
import Login3 from "./pages/login3";
import Login4 from "./pages/login4";

import { useEffect, useState } from "react";
import { customizacaoService } from "./service/customizacao/customizacao";
import { useCustomizacaoOptions } from "./context/CustomizacaoOptions";

export default function Rotas() {
  const { getCustomizacao } = customizacaoService;
  const [loginOpt, setLoginOpt] = useState(null);
  const { definirCustomizacao } = useCustomizacaoOptions();

  useEffect(() => {
    let vhost_orig = window.location.hostname;
    let vhost = window.location.hostname;
    vhost = vhost.replace(".com.br", "");
    vhost = vhost.replace(".cob.cloud", "");
    vhost = vhost.replace(".cobcloud", "");
    vhost = vhost.replace("negocie", "");
    vhost = vhost.replace("quintocred", "");
    vhost = vhost.replace("-novo", "");
    vhost = vhost.replace("-", "");
    vhost = vhost.replace(".", "");
    vhost = vhost.toUpperCase();

    // para testar no localhost (vhost = conta) no tb_empresas
    if (vhost_orig === "localhost") vhost = "MODELO";
    //if (vhost_orig === "localhost") vhost = "IAFSOLUCOES";

    async function dadosCustomizacao() {
      const response = await getCustomizacao(vhost);
      if (response.status === 200) {
        const options = response.data.rows[0];
        if (!options) return;

        definirCustomizacao(options);
        const { modelo_login } = options;
        const tipo = modelo_login || 1;

        if (tipo === 1) {
          setLoginOpt(<Login />);
        } else if (tipo === 2) {
          setLoginOpt(<Login2 />);
        } else if (tipo === 3) {
          setLoginOpt(<Login3 />);
        } else if (tipo === 4) {
          setLoginOpt(<Login4 />);
        } else {
          setLoginOpt(<Login />);
        }
      }
    }

    dadosCustomizacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomizacao]);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Auth autenticado={false}></Auth>} />
        <Route
          path="/principal/:id"
          element={
            <Auth autenticado={true}>
              <Header />
              <Home />
              <Footer />
            </Auth>
          }
        />
        <Route
          path="/negociacao/:id"
          element={
            <Auth autenticado={true}>
              <Header />
              <Negociacao />
              <Footer />
            </Auth>
          }
        />
        <Route path="/login" element={loginOpt} />
      </Routes>
    </BrowserRouter>
  );
}
