const userIsAuthenticate = () => {
  const nDocTelaPrincipal = window.location.search.substring(3);
  const nDocProposta = window.location.search.substring(3);
  const path = window.location.pathname.split("/")[1];

  const authData = JSON.parse(localStorage.getItem("user")) || {};

  const { x: md5TelaInicial, y: md5TelaNegociacao } = authData;

  // para tela principal
  const numeroDoc = md5TelaInicial;

  // para tela de proposta
  const numeroDocProposta = md5TelaNegociacao;

  if (path === "principal" && nDocTelaPrincipal === numeroDoc) {
    return true;
  }

  if (path === "negociacao" && nDocProposta === numeroDocProposta) {
    return true;
  }
  return false;
};

export const Authenticate = {
  userIsAuthenticate,
};
