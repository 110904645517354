import { useEffect, useState } from "react";

import { formatarCPFouCNPJ } from "../../../utils/formatarCpfCnpj";

import styles from "./styles.module.css";

export default function ApresentacaoCard() {
  const { card_apresentacao, cpf_cnpj, debitos, propostas } = styles;
  const [cpfCnpj, setCpfCnpj] = useState("");

  useEffect(() => {
    const { cpfCnpj: userCpfCnpj } =
      JSON.parse(localStorage.getItem("user")) || {};
    if (userCpfCnpj) {
      const cpfCnpjFormatado = formatarCPFouCNPJ(userCpfCnpj);
      setCpfCnpj(cpfCnpjFormatado);
    }
  }, []);

  return (
    <div className={card_apresentacao}>
      <span className={cpf_cnpj}>{`CPF/CNPJ: ${cpfCnpj}`}</span>
      <p className={debitos}>Débitos localizados</p>
      <p className={propostas}>Confira as propostas para os seus débitos</p>
    </div>
  );
}
