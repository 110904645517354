import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  FormHelperText,
  TextField,
  ThemeProvider,
  createTheme,
  Paper,
} from "@mui/material";

import { PatternFormat } from "react-number-format";
import PhoneInput from "../../components/utils/PhoneInput";

import Notification from "../../components/utils/Notification";
import TermosAceito from "../../components/modals/termosAceita";

import colors from "../../constant/colors";
import { loginService } from "../../service/login/login";
import { WhatsappLogo } from "@phosphor-icons/react";

import styles from "./styles.module.css";
import { useCustomizacaoOptions } from "../../context/CustomizacaoOptions";

import lupa from "../../assets/lupa.png";
import dinheiro from "../../assets/dinheiro.png";
import contrato from "../../assets/contrato.png";
import imgLogin from "../../assets/img-login.png";

const {
  container,
  card,
  left_side,
  right_side,
  imagem_container,
  title,
  sub_title,
  title_header,
  right_side_main,
  right_side_bottom,
  button,
  imagem,
  title_left_side,
  title_left_side_container,
  imagem_row,
  imagem_login,
  button_whatsapp,
} = styles;

const theme = createTheme({
  palette: {
    primary: { main: colors.BUTTON_COLOR },
  },
});

export default function Login() {
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [errorTelefone, setErrorTelefone] = useState(false);
  const [invisibleConta, setInvisibleConta] = useState(true);
  const [ip, setIp] = useState("");
  const [conta, setConta] = useState("");
  const [dadosAssessoria, setDadosAssessoria] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [cpfCnpjNaoEncontrado, setCpfCnpjNaoEncontrado] = useState(false);
  const [telefoneAssessoria1, setTelefoneAssessoria1] = useState("");
  const [telefoneAssessoria2, setTelefoneAssessoria2] = useState("");
  const [termoAssessoria, setTermoAssessoria] = useState("");
  const [urlWhats, setUrlWhats] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState("");
  const [alertInfo, setAlertInfo] = useState("");
  const [isTermosAceito, setIsTermosAceito] = useState(false);
  const [errorTermosAceito, setErrorTermosAceito] = useState(false);
  const [openTermos, setOpenTermos] = useState(false);

  const [corFundoBotao, setCorFundoBotao] = useState("#ffffff");
  const [corTextoBotao, setCorTextoBotao] = useState("#ffffff");

  const { getMyIp, getDadosAssessoria, login } = loginService;
  const navigate = useNavigate();

  const { customizacaoOptions } = useCustomizacaoOptions();

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.button_color;
      const corTexto = customizacaoOptions.button_text_color;
      setCorFundoBotao(fundo);
      setCorTextoBotao(corTexto);
    }
  }, [customizacaoOptions]);

  useEffect(() => {
    let vhost_orig = window.location.hostname;
    let vhost = window.location.hostname;
    vhost = vhost.replace(".com.br", "");
    vhost = vhost.replace(".cob.cloud", "");
    vhost = vhost.replace(".cobcloud", "");
    vhost = vhost.replace("negocie", "");
    vhost = vhost.replace("quintocred", "");
    vhost = vhost.replace("-novo", "");
    vhost = vhost.replace("-", "");
    vhost = vhost.replace(".", "");
    vhost = vhost.toUpperCase();

    if (vhost_orig === "localhost") setInvisibleConta(false);
    else setInvisibleConta(true);

    async function myIp() {
      const response = await getMyIp();
      const { meuIP } = response;
      setIp(meuIP);
    }

    myIp();

    setConta(vhost);
  }, [getMyIp]);

  useEffect(() => {
    async function dadosAssessoria() {
      const response = await getDadosAssessoria(conta);
      const dados = response.data?.rows[0];
      setDadosAssessoria(dados);
    }
    if (conta) {
      dadosAssessoria();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conta]);

  useEffect(() => {
    if (dadosAssessoria) {
      setTelefoneAssessoria1(dadosAssessoria.emp_fone1);
      setTelefoneAssessoria2(dadosAssessoria.emp_fone2);
      setUrlWhats(dadosAssessoria.portalneg_whats);

      if ((dadosAssessoria.portalneg_termo || "").length < 10) {
        setIsTermosAceito(true);
        setTermoAssessoria("");
      } else {
        setIsTermosAceito(false);
        setTermoAssessoria(dadosAssessoria.portalneg_termo);
      }
    }
  }, [dadosAssessoria]);

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignIn = async () => {
    if (!isTermosAceito) {
      setErrorTermosAceito(true);
      return;
    }
    setDisableButton(true);
    const isCpfCnpjValido = validateCpfCnpj();
    const isTelefoneValido = validateTelefone();

    try {
      if (isCpfCnpjValido && isTelefoneValido) {
        const body = { cnpj: cpfCnpj, conta, telefone, meuIP: ip };
        const response = await login(body);
        if (response?.status === 401) {
          setCpfCnpjNaoEncontrado(true);
          return;
        }
        const { data } = response;
        if (data) {
          const authData = {
            x: data.cnpjmd5,
            y: "",
            cpfCnpj: cpfCnpj,
          };

          localStorage.setItem("user", JSON.stringify(authData));
          navigate(`/principal/${data.empresamd5}?d=${data.cnpjmd5}`);
          setCpfCnpjNaoEncontrado(false);
        }
      }
    } catch (error) {
      setAlertInfo("Erro ao tentar acessar a plataforma!");
      setAlertInfoTipo("error");
      setOpenAlert(true);
    } finally {
      setDisableButton(false);
    }
  };

  const validateTelefone = () => {
    const formattedTelefone = telefone.trim();
    const isValid =
      Boolean(formattedTelefone) &&
      (formattedTelefone.length === 10 || formattedTelefone.length === 11);
    setErrorTelefone(!isValid);
    return isValid;
  };

  const validateCpfCnpj = () => {
    const formattedCpfCnpj = cpfCnpj.trim();
    const isValid =
      Boolean(formattedCpfCnpj) &&
      (formattedCpfCnpj.length === 14 || formattedCpfCnpj.length === 11);
    setErrorCpfCnpj(!isValid);
    return isValid;
  };

  return (
    <div className={container}>
      <Card className={card}>
        <Paper elevation={3} className={left_side}>
          <div className={imagem_row}>
            <div className={title_left_side_container}>
              <span className={title_left_side}>Funcionamento</span>
            </div>
            <div className={imagem_container} style={{ marginBottom: "1rem" }}>
              <img
                alt="imagem lupa"
                src={lupa}
                height={60}
                className={imagem}
              />
              <span>
                1. Informe seu CPF/CNPJ e número de celular e veja se possui
                débitos.
              </span>
            </div>
            <div className={imagem_container} style={{ marginBottom: "1rem" }}>
              <img
                alt="imagem dinheiro"
                src={dinheiro}
                height={60}
                className={imagem}
              />
              <span>
                2. Tenha acesso as melhores condições de pagamento e faça um
                acordo.
              </span>
            </div>
            <div className={imagem_container}>
              <img
                alt="imagem contrato"
                src={contrato}
                height={60}
                className={imagem}
              />
              <span>
                3. Receba seu boleto e pague em qualquer banco até a data de
                vencimento.
              </span>
            </div>
          </div>
          <div className={imagem_login}>
            <img
              height={larguraDaTela >= 1800 ? 350 : ""}
              alt="imagem login"
              src={imgLogin}
            />
          </div>
        </Paper>
        <div className={right_side}>
          <div className={title_header}>
            <span className={title}>Portal de Negociação</span>
            <span className={sub_title}>
              Tenha acesso a propostas de pagamento com segurança, praticidade e
              rapidez.
            </span>
          </div>
          <div className={right_side_main}>
            <TextField
              label="Conta"
              variant="outlined"
              id="conta"
              placeholder="Conta"
              fullWidth
              type="text"
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                display: invisibleConta ? "none" : "",
                marginTop: "1rem",
              }}
              value={conta}
              onChange={(e) => setConta(e.target.value.toUpperCase())}
            />
            <PatternFormat
              format={
                cpfCnpj.length <= 11
                  ? "###.###.###-#####"
                  : "##.###.###/####-##"
              }
              customInput={TextField}
              id="cpfCnpj"
              fullWidth
              type="text"
              label="CPF / CNPJ"
              variant="outlined"
              placeholder="Digite seu CPF ou CNPJ"
              color="primary"
              style={{
                marginTop: "1rem",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={cpfCnpj}
              onValueChange={(e) => setCpfCnpj(e.value)}
              onBlur={() => validateCpfCnpj()}
              error={errorCpfCnpj}
            />
            {errorCpfCnpj && (
              <FormHelperText error>
                Campo "CPF / CNPJ" obrigatório
              </FormHelperText>
            )}
            <PhoneInput
              label="Telefone"
              style={{
                marginTop: "1rem",
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                colors: "red",
              }}
              placeholder="(11) 9 9999 - 9999"
              fullWidth
              id="telefone"
              type="tel"
              value={telefone}
              onValueChange={(e) => setTelefone(e.value)}
              onBlur={validateTelefone}
              error={errorTelefone}
            />
            {errorTelefone && (
              <FormHelperText error>
                Campo "Telefone" obrigatório
              </FormHelperText>
            )}
          </div>
          <div className={right_side_bottom}>
            <div
              style={{
                width: "100%",
                display: !termoAssessoria ? "none" : "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "1rem",
                padding: "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={isTermosAceito}
                  onChange={() => {
                    setErrorTermosAceito(false);
                    setIsTermosAceito(!isTermosAceito);
                  }}
                />
                Li e aceito os termos de uso.
                <span
                  style={{
                    color: "#4287f5",
                    cursor: "pointer",
                    marginLeft: "1em",
                  }}
                  onClick={() => setOpenTermos(true)}
                >
                  LEIA AQUI
                </span>
              </div>
              {errorTermosAceito && (
                <FormHelperText error>
                  Leia nossos termos de uso e marque a opção acima
                </FormHelperText>
              )}
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <Button
                  className={button}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSignIn}
                  disabled={disableButton}
                  style={{
                    marginRight: "5px",
                    backgroundColor: corFundoBotao,
                    color: corTextoBotao,
                  }}
                >
                  Quero negociar
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <Card
            hidden={!cpfCnpjNaoEncontrado}
            style={{
              marginTop: "1rem",
              padding: "0.5rem",
              background: "rgba(241, 123, 102, 0.39)",
              textAlign: "center",
            }}
          >
            Não encontramos seu CPF/CNPJ em nosso portal de negociações.
            <br />
            {`Caso você tenha recebido um SMS ou um e-mail para ter acesso a uma
            proposta de pagamento entre em contato com nossa central de
            atendimento no(s) telefone(s) ${
              telefoneAssessoria1 && telefoneAssessoria2
                ? `${telefoneAssessoria1} ou ${telefoneAssessoria2}`
                : telefoneAssessoria1 || telefoneAssessoria2
            }.`}
          </Card>
        </div>
      </Card>
      <div hidden={!urlWhats}>
        <a
          className={button_whatsapp}
          href={urlWhats}
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappLogo size={32} color="#fff" />
        </a>
      </div>

      <Notification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />

      <TermosAceito
        isModalTermosAceitostaOpen={openTermos}
        closeModalTermosAceito={() => setOpenTermos(false)}
        termos={termoAssessoria}
      />
    </div>
  );
}
