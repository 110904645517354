import { useEffect, useState } from "react";

import { Button, ThemeProvider, createTheme } from "@mui/material";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AceitarPropostaModal from "../../modals/aceitarProposta";
import SolicitarAuxilioModal from "../../modals/solicitarAuxilio";
import { useCustomizacaoOptions } from "../../../context/CustomizacaoOptions";

import styles from "./styles.module.css";
import formatMoney from "../../../utils/formatMoney";

const {
  proposta_card,
  header,
  header_text,
  footer,
  button,
  valor_entrada,
  title,
  valor,
  data_vencimento,
  vencimento,
  parcelas,
  slider,
  button_auxilio,
  container,
  container_body,
  container_button_auxilio,
} = styles;

export default function PropostaCard({ isLoading, propostas }) {
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const [openAceitarProposta, setOpenAceitarProposta] = useState(false);
  const [openSolicitarAuxilio, setOpenSolicitarAuxilio] = useState(false);
  const [propostaSelecionada, setPropostaSelecionada] = useState({});
  const [corFundoBotao, setCorFundoBotao] = useState("#AEB6BF");
  const [corFundoHeaderCard, setCorFundoHeaderCard] = useState("#21489b");
  const [corTextoHeaderCard, setCorTextoHeaderCard] = useState("#ffffff");
  const [corTextoBotao, setCorTextoBotao] = useState("#fff");
  const [textoBotaoContraProposta, setTextoBotaoContraProposta] = useState(
    "Solicitar auxílio de um operador"
  );

  const { customizacaoOptions } = useCustomizacaoOptions();

  const theme = createTheme({
    palette: {
      primary: { main: corFundoBotao },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.button_color;
      const corTexto = customizacaoOptions.button_text_color;
      const corHeaderCard = customizacaoOptions.card_color;
      const corTextoHeaderCard = customizacaoOptions.card_text_color;
      const textoContraProposta =
        customizacaoOptions.button_contra_proposta_text;
      setCorFundoBotao(fundo);
      setCorTextoBotao(corTexto);
      setTextoBotaoContraProposta(textoContraProposta);
      setCorFundoHeaderCard(corHeaderCard);
      setCorTextoHeaderCard(corTextoHeaderCard);
    }
  }, [customizacaoOptions]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: propostas.length > 3 ? 3 : propostas.length,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          centerMode: true,
          slidesToShow: propostas.length > 3 ? 4 : propostas.length,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAceitarProposta = (id) => {
    const propostaSelecionada = propostas.find(
      (proposta) => proposta.id === id
    );
    setPropostaSelecionada(propostaSelecionada);
    setOpenAceitarProposta(true);
  };

  const propostaBody = propostas.map((proposta, index) => {
    if (proposta.vl_parcelas_rest > 0 || proposta.vl_entrada > 0) {
      return (
        <div key={index} className={slider}>
          <div
            className={proposta_card}
            style={{
              margin:
                larguraDaTela >= 830
                  ? `0.8rem ${propostas.length >= 2 ? "1rem" : "0"}  1rem 0`
                  : "",
            }}
          >
            <div
              className={header}
              style={{ backgroundColor: corFundoHeaderCard }}
            >
              <span
                className={header_text}
                style={{ color: corTextoHeaderCard }}
              >
                {proposta.descricao_neg1}
              </span>
            </div>
            <div className={valor_entrada}>
              <span
                hidden={proposta.qtde_saldo_parcelas == 0}
                className={title}
              >
                Valor de Entrada
              </span>
              <span hidden={proposta.qtde_saldo_parcelas > 0} className={title}>
                Valor à Vista
              </span>
              <span className={valor}>{`R$ ${formatMoney(
                proposta.vl_entrada
              )}`}</span>
            </div>
            <div
              className={data_vencimento}
              style={{ backgroundColor: `${corFundoHeaderCard}28` }}
            >
              <span className={title}>
                Vencimento:{" "}
                <span className={vencimento}>
                  {proposta.vencimento_entrada}
                </span>
              </span>
            </div>
            {proposta.qtde_saldo_parcelas > 0 && (
              <div className={parcelas}>
                <span>{`+ ${
                  proposta.qtde_saldo_parcelas
                } parcela(s) de R$ ${formatMoney(
                  proposta.vl_parcelas_rest
                )}`}</span>
                <span>{`com intervalo de pagamento de ${proposta.intervalo_parc} dias`}</span>
              </div>
            )}
            <div className={footer}>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color="primary"
                  className={button}
                  onClick={() => handleAceitarProposta(proposta.id)}
                  style={{ color: corTextoBotao }}
                >
                  <SpellcheckIcon
                    style={{ marginRight: "5px", fontSize: "18px" }}
                  />
                  Aceitar Proposta
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  });

  function rederModals() {
    return (
      <>
        <AceitarPropostaModal
          isModalAceitarPropostaOpen={openAceitarProposta}
          closeModalAceitarProposta={() => setOpenAceitarProposta(false)}
          propostaSelecionada={propostaSelecionada}
          botao={{ corFundoBotao, corTextoBotao }}
        />
        <SolicitarAuxilioModal
          isModalSolicitarAuxilioOpen={openSolicitarAuxilio}
          closeModalSolicitarAuxilio={() => setOpenSolicitarAuxilio(false)}
          propostas={propostas}
          botao={{ corFundoBotao, corTextoBotao }}
        />
      </>
    );
  }

  return (
    <>
      <div className={container}>
        <div className={container_body}>{propostaBody}</div>

        <div>
          {!isLoading && (
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                size="large"
                onClick={() => setOpenSolicitarAuxilio(true)}
                className={button_auxilio}
                style={{ color: corTextoBotao }}
              >
                <HeadsetMicIcon
                  style={{ marginRight: "10px", fontSize: "18px" }}
                />
                {textoBotaoContraProposta}
              </Button>
            </ThemeProvider>
          )}
        </div>
      </div>
      {rederModals()}
    </>
  );

  /*
  return larguraDaTela >= 600 && propostas.length > 3 ? (
    <>
      <div style={{ width: "95%" }}>
        <Slider {...settings}>{propostaBody}</Slider>

        <div className={container_button_auxilio}>
          {!isLoading && (
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                size="large"
                onClick={() => setOpenSolicitarAuxilio(true)}
                className={button_auxilio}
                style={{ color: corTextoBotao }}
              >
                <HeadsetMicIcon
                  style={{ marginRight: "10px", fontSize: "18px" }}
                />
                {textoBotaoContraProposta}
              </Button>
            </ThemeProvider>
          )}
        </div>
      </div>
      {rederModals()}
    </>
  ) : (
    <>
      <div className={container}>
        <div className={container_body}>{propostaBody}</div>

        <div>
          {!isLoading && (
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                size="large"
                onClick={() => setOpenSolicitarAuxilio(true)}
                className={button_auxilio}
                style={{ color: corTextoBotao }}
              >
                <HeadsetMicIcon
                  style={{ marginRight: "10px", fontSize: "18px" }}
                />
                {textoBotaoContraProposta}
              </Button>
            </ThemeProvider>
          )}
        </div>
      </div>
      {rederModals()}
    </>
  );
  */
}
